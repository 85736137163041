import React, { useState, useRef, useEffect } from 'react';

const App = () => {
  const commonEmojis = ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣'];
  const rareEmojis = ['🥸', '🤪', '🥳', '👽', '🤖', '👺', '👾', '🤡'];
  const [currentEmoji, setCurrentEmoji] = useState(commonEmojis[0]);
  const audioRef = useRef(null);
  const [audioNumber, setAudioNumber] = useState(1);
  const [randomChars, setRandomChars] = useState([]);
  const [isRare, setIsRare] = useState(false);
  const [playedAudios, setPlayedAudios] = useState([]); // Keep track of played audios

  useEffect(() => {
    const interval = setInterval(() => {
      const size = Math.random() * 2; // Generate size
      const color = isRare ? `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})` : `rgb(${Math.floor(255 - size * 100)}, ${Math.floor(255 - size * 100)}, ${Math.floor(255 - size * 100)})`;
      setRandomChars(prevChars => [
        ...prevChars,
        {
          char: String.fromCharCode(Math.floor(Math.random() * (127 - 33) + 33)),
          top: `${Math.random() * 100}vh`,
          left: `${Math.random() * 100}vw`,
          size: `${size}em`, // Add size property
          color: color // Add grayscale color property
        },
        {
          char: `${audioNumber}`,
          top: `${Math.random() * 100}vh`,
          left: `${Math.random() * 100}vw`,
          size: `${size}em`, // Add size property
          color: color // Add grayscale color property
        }
      ].filter(char => !/[a-zA-Z0-9]/.test(char.char))); // Exclude alphanumerics
    }, 1000);
    return () => clearInterval(interval);
  }, [audioNumber, isRare]);

  const handleClick = async () => {
    let newEmoji;
    let isRareEmoji;
    do {
      isRareEmoji = Math.random() < 0.05; // Increase the chance of a rare emoji to 30%
      const emojis = isRareEmoji ? rareEmojis : commonEmojis;
      newEmoji = emojis[Math.floor(Math.random() * emojis.length)];
    } while (newEmoji === currentEmoji);
    setCurrentEmoji(newEmoji);
    setIsRare(isRareEmoji);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    let audioNum;
    do {
      audioNum = Math.floor(Math.random() * 145) + 1;
    } while (playedAudios.includes(audioNum)); // Ensure the audio hasn't been played before
    setPlayedAudios([...playedAudios, audioNum]); // Add the audio to the played list
    if (playedAudios.length >= 145) {
      setPlayedAudios([]); // Reset the played list if all audios have been played
    }
    const audioUrl = `sounds/audio (${audioNum}).mp3`; // Changed from WAV to MP3
    const cache = await caches.open('audio-cache');
    const response = await cache.match(audioUrl);
    if (!response) {
      const fetchResponse = await fetch(audioUrl);
      cache.put(audioUrl, fetchResponse.clone());
    }
    const audioBlob = await cache.match(audioUrl);
    const audio = new Audio(URL.createObjectURL(await audioBlob.blob()));
    audio.play();
    audioRef.current = audio;
    setAudioNumber(audioNum);
  };

  return (
    <div style={{ position: 'relative', height: '100vh', backgroundColor: isRare ? `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)})` : 'white' }}>
      {randomChars.map((char, index) => (
        <div key={index} style={{ position: 'absolute', top: char.top, left: char.left, fontSize: char.size, color: char.color }}>
          {char.char}
        </div>
      ))}
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', zIndex: 1 }}>
        <button onClick={handleClick} style={{ fontSize: '50px', padding: '10px 20px' }}>
          {currentEmoji}
        </button>
        <div style={{ fontSize: '20px' }}>
          {audioNumber}
        </div>
      </div>
    </div>
  );
};

export default App;